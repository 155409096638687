import { Button } from '@finn/design-system/atoms/button';

import { IComparisonTableFooterLinkAction } from '~/types/comparisonTable';

const LinkFooterAction = ({
  action,
  fullWidth,
}: {
  action: IComparisonTableFooterLinkAction;
  fullWidth?: boolean;
}) => {
  return (
    <Button
      variant="outlineWhite"
      href={action.href}
      className={fullWidth ? 'w-full' : ''}
    >
      {action.title}
    </Button>
  );
};

export default LinkFooterAction;
