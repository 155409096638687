import RawHtml from '@finn/auto-ui/components/RawHtml';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';
import React, { SyntheticEvent } from 'react';

import { IComparisonTableItem } from '~/types/comparisonTable';

import Action from '../../Action';
import Price from '../../Price';

interface IProps {
  title: string;
  items: IComparisonTableItem[];
  summaryRow?: string;
}
const TableFooter: React.FunctionComponent<IProps> = ({
  title,
  items,
  summaryRow = '',
}) => {
  const isMobile = useMediaQuery('sm');

  const handleSourceLinkClicked = (event: SyntheticEvent) => {
    if ((event.target as HTMLAnchorElement).tagName === 'A') {
      interactionTrackingEvent(TrackingEventName.SOURCE_LINK_CLICKED, {
        type: 'leasingmarkt',
      });
    }
  };

  return (
    <tfoot>
      <tr
        className={[
          summaryRow ? 'h-9 sm:h-[38px]' : 'h-[100px]',
          !title && '!h-0',
        ].join(' ')}
      >
        {summaryRow ? (
          <>
            <td className="body-12-light pt-2 sm:pr-2">
              {!isMobile && (
                <div onClick={(event) => handleSourceLinkClicked(event)}>
                  <RawHtml html={title} />
                </div>
              )}
            </td>
            <td
              className="body-14-semibold sm:body-16-semibold bg-green text-center text-white"
              colSpan={2}
            >
              {summaryRow}
            </td>
          </>
        ) : (
          <>
            <td>{title}</td>
            {items.map((item, idx) => (
              <td
                key={idx}
                className={cn('p-4 text-center', {
                  'rounded-bl-sm rounded-br-sm bg-black text-white':
                    item.featured,
                })}
              >
                {item.footer?.price && (
                  <Price price={item.footer.price} className="mb-4" />
                )}
                {item.footer?.action && <Action action={item.footer.action} />}
              </td>
            ))}
          </>
        )}
      </tr>
      {isMobile && summaryRow && (
        <tr>
          <td className="pt-2 sm:pr-2" colSpan={3}>
            <RawHtml html={title} />
          </td>
        </tr>
      )}
    </tfoot>
  );
};

export default TableFooter;
