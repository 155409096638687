import { cn } from '@finn/ui-utils';
import React from 'react';

import {
  IComparisonTable,
  IComparisonTableHeader,
  IComparisonTableMetadata,
  ImageObjectData,
} from '~/types/comparisonTable';

import TableBody from './Body/TableBody';
import TableFooter from './Footer/TableFooter';
import TableHeader from './Header/TableHeader';

type TableProps = {
  data: IComparisonTable;
  image?: ImageObjectData;
  className?: string;
};

const Table = ({
  data,
  className,
  image = {} as ImageObjectData,
}: TableProps) => {
  const { metadata = {} as IComparisonTableMetadata, items = [] } = data;

  const headers = items.map((item) => ({
    featured: item.featured,
    ...(item.header || ({} as IComparisonTableHeader)),
  }));

  return (
    <table className={cn('border-spacing-0', className)}>
      <TableHeader header={metadata.header} image={image} items={headers} />
      <TableBody dimensions={metadata.dimensions} items={items} />
      <TableFooter title={metadata.footer.title} items={items} />
    </table>
  );
};

export default Table;
