import { useMediaQuery } from '@finn/design-system/helpers/media';
import React from 'react';

import { IComparisonTable } from '~/types/comparisonTable';
import { ComparisonCosmicMetadata } from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

import ComparisonAccordion from './ComparisonAccordian/ComparisonAccordian';
import ComparisonTable from './ComparisonTable/ComparisonTable';

interface IProps {
  data: ComparisonCosmicMetadata;
}

const BaseComparisonSection: React.FunctionComponent<IProps> = ({ data }) => {
  const {
    title,
    description,
    table = {} as IComparisonTable,
    header_image: tableImage,
  } = data;

  const isMobile = useMediaQuery('md');

  return (
    <div className="container">
      {title !== '' && (
        <h2 className="mobile-t2-semibold md:web-t2-semibold mb-6 w-[960px] max-w-full">
          {title}
        </h2>
      )}

      {description !== '' && (
        <div className="body-16-light mb-10 w-[960px] max-w-full md:mb-20">
          {parseToHtml(description)}
        </div>
      )}

      {!isMobile && <ComparisonTable data={table} image={tableImage} />}

      {isMobile && <ComparisonAccordion data={table} />}
    </div>
  );
};

export default BaseComparisonSection;
