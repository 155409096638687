import {
  IComparisonTableBaseAction,
  IComparisonTableFooterLinkAction,
} from '~/types/comparisonTable';

import AddToCart from './AddToCart';
import LinkFooterAction from './LinkFooterAction';

const Action = ({
  action,
  fullWidth,
}: {
  action: IComparisonTableBaseAction;
  fullWidth?: boolean;
}) => {
  switch (action.type) {
    case 'link':
      return (
        <LinkFooterAction
          action={action as IComparisonTableFooterLinkAction}
          fullWidth={fullWidth}
        />
      );
    case 'add_to_cart':
      return (
        <AddToCart
          title={action.title}
          buttonClassName="mt-6 sm:m-6"
          isMainButton={false}
        />
      );
    default:
      return null;
  }
};

export default Action;
