import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { InfoToolTip } from '@finn/ui-components';
import { cn } from '@finn/ui-utils';

import {
  IComparisonTableItemBaseValue,
  IComparisonTableMetadataDimension,
} from '~/types/comparisonTable';

import TableCellData from './TableCellData';

type Props = {
  dimensions: IComparisonTableMetadataDimension[];
  items: {
    featured: boolean;
    values: Record<string, IComparisonTableItemBaseValue>;
  }[];
};
const TableBody = ({ dimensions, items }: Props) => {
  return (
    <tbody>
      {dimensions.map((dimension, idx) => (
        <tr key={idx}>
          <td
            className={cn(
              'border-0 border-t border-solid',
              'h-[72px] w-[680px] p-0 pr-6',
              'border-pearl global-t5-semibold before:inline after:inline'
            )}
          >
            {dimension.description && (
              <div className="flex w-max items-center [&_svg]:w-5">
                <div className="mr-2">{dimension.name}</div>
                <div className="cursor-pointer">
                  <InfoToolTip
                    content={dimension.description}
                    Icon={InfoOutlined}
                  />
                </div>
              </div>
            )}
            {!dimension.description && (
              <>
                {dimension.name}
                {dimension?.additionalText && (
                  <span className="body-12-light">
                    {dimension.additionalText}
                  </span>
                )}
              </>
            )}
          </td>
          {items.map((item, itemIdx) => (
            <td
              key={itemIdx}
              className={cn(
                'w-[190px] border border-x-0 border-b-0 border-solid text-center',
                {
                  'border-black bg-black text-white': item.featured,
                  'border-pearl': !item.featured,
                }
              )}
            >
              {item.values[dimension.id] && (
                <TableCellData
                  featured={item.featured}
                  data={item.values[dimension.id]}
                />
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
